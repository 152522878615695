import { ITiptapContent } from '../../../../base/ui/text-editor/tiptap-shape-text-editor';
import { ITextContent, DEFUALT_TEXT_STYLES, fontsMap } from 'flux-definition';
import { DOMSerializer, Node as PMNode, Schema, DOMParser } from '@tiptap/pm/model';
import { createHTMLDocument, VHTMLDocument, parseHTML } from 'zeed-dom';

export class TiptapHelper {

    public static convertCarotaToTiptap( content: ITextContent[]) {
        const paras = [];
        let nthPara = 0;
        paras.push({
            type: 'paragraph',
            attrs: {
                textAlign: DEFUALT_TEXT_STYLES.align,
            },
            content: [],
        });
        let lastAlign;
        for ( let i = 0; i < content.length; i++ ) {
            const cur = content[i];
            lastAlign = cur.align || DEFUALT_TEXT_STYLES.align;
            let para = paras[ nthPara ];
            para.attrs.textAlign = lastAlign;
            const lines = cur.text === '' ? [ '' ] : cur.text.split( /(\n)/ ).filter( emptyText => emptyText );
            for ( const line of lines ) {
                if ( line === '\n' ) {
                    nthPara++;
                    paras.push({
                        type: 'paragraph',
                        attrs: {
                            textAlign: lastAlign,
                        },
                        content: [],
                    });
                    para = paras[ nthPara ];
                    continue;
                    // this.addNewLineBreakToDeltas( deltas, lastAlign );
                }
                if ( line === '' ) {
                    continue;
                }
                const marks = [];
                if ( cur.bold ) {
                    marks.push({ type: 'bold' });
                }
                if ( cur.italic ) {
                    marks.push({ type: 'italic' });
                }
                if ( cur.underline ) {
                    marks.push({ type: 'underline' });
                }
                if ( cur.strikeout ) {
                    marks.push({ type: 'strike' });
                }
                if ( cur.link ) {
                    marks.push({
                        type: 'link',
                        attrs: { href: cur.link },
                    });
                }
                marks.push({
                    type: 'textStyle',
                    attrs: { color: cur.color, fontSize: cur.size + 'pt', fontFamily: cur.font },
                });
                para.content.push({
                    type: 'text',
                    text: line,
                    marks,
                });
            }
        }
        return paras;
    }

    public static convertTiptapToCarota( content: ITiptapContent[]) {
        const allRuns = [];
        for ( let i = 0; i < content.length; i++ ) {
            const item = content[i];
            if ( item.attrs?.dir === 'rtl' ) { // Carota can't handle RTL
                return;
            }
            if ( item.type === 'paragraph' ) {
                const alignment = item.attrs?.textAlign || 'center';
                const runs: ITextContent[] = [];

                for ( let j = 0; j < ( item.content || []).length; j++ ) {
                    const run = item.content[ j ];
                    let bold = DEFUALT_TEXT_STYLES.bold;
                    let italic = DEFUALT_TEXT_STYLES.italic;
                    let strikeout = DEFUALT_TEXT_STYLES.strikeout;
                    let underline = DEFUALT_TEXT_STYLES.underline;
                    let color = DEFUALT_TEXT_STYLES.color;
                    let size = DEFUALT_TEXT_STYLES.size;
                    let font = DEFUALT_TEXT_STYLES.font;
                    let link = null;
                    if ( run.type === 'text' ) {
                        const text = run.text;
                        ( run.marks || []).forEach( m => {
                            if ( m.type === 'bold' ) {
                                bold = true;
                            }
                            if ( m.type === 'italic' ) {
                                italic = true;
                            }
                            if ( m.type === 'strike' ) {
                                strikeout = true;
                            }
                            if ( m.type === 'underline' ) {
                                underline = true;
                            }
                            if ( m.type === 'textStyle' ) {
                                if ( m.attrs.color ) {
                                    color = m.attrs.color;
                                }
                                if ( m.attrs.fontSize ) {
                                    size = parseFloat(
                                        TiptapHelper.validateFontSize( m.attrs.fontSize ),
                                    );
                                }
                                if ( m.attrs.fontFamily ) {
                                    font = TiptapHelper.validateFont(  m.attrs.fontFamily );
                                }
                            }
                            if ( m.type === 'link' ) {
                                if ( m.attrs.href ) {
                                    link = m.attrs.href;
                                }
                            }
                        });
                        const carotaRun = {
                            align: alignment, link, underline,
                            text, bold, italic, strikeout, color, size, font,
                        };
                        runs.push( carotaRun );
                    } else if ( run.type === 'emoji' ) {
                        const name = run.attrs.name;
                        const emojiData: HTMLElement = document.querySelector( `span[data-type="emoji"][data-name="${name}"]` );
                        const carotaRun = {
                            align: alignment, link, underline,
                            text: emojiData.innerText, bold, italic, strikeout, color, size, font,
                        };
                        runs.push( carotaRun );
                    } else if ( run.type === 'hardBreak' ) {
                        const carotaRun = {
                            align: alignment, link, underline,
                            text: '\n', bold, italic, strikeout, color, size, font,
                        };
                        runs.push( carotaRun );
                    } else {
                        // Note: can't convert tiptap to carota
                        return;
                    }
                }
                const last = runs[ runs.length - 1 ];
                if ( last && content[ i + 1 ] && content[ i + 1 ].type === 'paragraph' ) {
                    last.text = last.text + ( last.text !== '\n' ? '\n' : '' );
                }
                if ( !item.content ) { // Empty paragraph === new line
                    runs.push({
                        ...DEFUALT_TEXT_STYLES, text: '\n', align: alignment,
                    });
                }
                allRuns.push( ...runs );
            } else {
                // Note: can't convert tiptap to carota
                return;
            }
        }
        return allRuns;
    }

    public static convertTiptapHtmlToCarota( html: string, schema ) {
        const tiptap = TiptapHelper.generateJSON( html, schema );
        return TiptapHelper.convertTiptapToCarota( tiptap.content as any );
    }

    public static convertCarotaToTiptapHtml( content: ITextContent[], schema ) {
        const tiptap = TiptapHelper.convertCarotaToTiptap( content );
        const contentNode = PMNode.fromJSON( schema, { type: 'doc', content: tiptap });
        return TiptapHelper.getHTMLFromFragment( contentNode as any, schema );
    }

    /**
     * The input font size string is converted pt
     * returns undefined if the font is not in pt or px
     */
    public static validateFontSize( size: string ) {
        if ( !size ) {
            return DEFUALT_TEXT_STYLES.size + 'pt';
        }
        if ( size.includes( 'pt' )) {
            return Math.round( parseFloat( size )) + 'pt';
        } else if ( size.includes( 'px' )) {
            return Math.round( parseFloat( size ) * 0.75 ) + 'pt';
        }
    }

    /**
     * The input font name is checked against the
     * currently available fonts, returns undefined if the font is not found
     */
    public static validateFont( name: string ) {
        if ( name ) {
            for ( const key in  fontsMap ) {
                if ( name === key || name === fontsMap[ key ]) {
                    return key;
                }
            }
        }
        return DEFUALT_TEXT_STYLES.font;
    }

    public static isUniformFormatWithNoLineBreaks( html: string ): boolean {
        const el = document.createElement( 'div' );
        el.innerHTML = html;
        let fEl = el as any;
        let isUniform = true;
        while ( fEl ) {
            if ( fEl.childNodes.length > 1 ) {
                isUniform = false;
                break;
            }
            fEl = fEl.firstChild;
        }
        return isUniform;
    }

    public static updateUniformFormatWithNoLineBreaks( html: string, text: string ) {
        const el = document.createElement( 'div' );
        el.innerHTML = html;

        let fEl = el.firstChild;
        while ( fEl ) {
            if ( !fEl.firstChild ) {
                break;
            }
            fEl = fEl.firstChild;
        }
        fEl.textContent = text;
        return el.innerHTML;
    }

    public static isConvertableToCarota( html: string, schema ): boolean {
        return !!TiptapHelper.convertTiptapHtmlToCarota( html, schema );
    }

    public static isListActive( editorName: string ) {
        const parentClass = `tiptap-editor-component.${editorName}`;
        const caretElement = TiptapHelper.getCaretElement() as HTMLElement;
        if ( caretElement ) {
            const tagName = caretElement.tagName.toLowerCase();
            const closestLi = [ 'ul' , 'ol' ].includes( tagName ) ? caretElement : caretElement.closest( 'ul, ol' );
            const topMostElement = document.querySelector( parentClass );
            if ( topMostElement && topMostElement.contains( closestLi )) {
                return true;
            }
        }
        return false;
    }

    public static getCaretElement() {
        const selection = document.getSelection();
        if ( selection && selection.focusNode ) {
          const focusNode = selection.focusNode;
          if ( focusNode.nodeType === Node.ELEMENT_NODE ) {
            return focusNode;
          } else if ( focusNode.parentNode && focusNode.parentNode.nodeType === Node.ELEMENT_NODE ) {
            return focusNode.parentNode;
          }
        }
        return null; // No valid caret element found
    }


    public static getHTMLFromFragment( doc: PMNode, schema: Schema, options?: { document?: Document }): string {
      if ( options?.document ) {
        // The caller is relying on their own document implementation. Use this
        // instead of the default zeed-dom.
        const wrap = options.document.createElement( 'div' );

        DOMSerializer.fromSchema( schema ).serializeFragment( doc.content, { document: options.document }, wrap );
        return wrap.innerHTML;
      }

      // Use zeed-dom for serialization.
      const zeedDocument = DOMSerializer.fromSchema( schema ).serializeFragment( doc.content, {
        document: createHTMLDocument() as unknown as Document,
      }) as unknown as VHTMLDocument;

      return zeedDocument.render();
    }

    public static generateJSON( html: string, schema: Schema ): Record<string, any> {
        const dom = parseHTML( html ) as unknown as Node;
        return DOMParser.fromSchema( schema ).parse( dom ).toJSON();
    }

}
